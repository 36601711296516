<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-button type="primary" @click="$router.push($route.path + '/add')"
        >新增</a-button
      >

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="名称" data-index="name"></a-table-column>
        <a-table-column title="链接" data-index="url">
          <template slot-scope="url">
            <a-space>
              <span>{{ url }}</span>
              <a-icon type="copy" @click="$copy(url)" style="color: #999" />
            </a-space>
          </template>
        </a-table-column>
        <a-table-column
          title="创建时间"
          data-index="updateDate"
          align="center"
        ></a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" style="color: #f00" @click.prevent="deleteText(text)"
              >删除</a
            >
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/setting/app-version";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      loading: false,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res && Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个版本吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>